import _ from 'lodash'
import store from '@/store'
import CheckerPipeline from './CheckerPipeline'
import RegisteredOnly from './Checkers/RegisteredOnly'
import PhoneVerifiedOnly from './Checkers/PhoneVerifiedOnly'

function useGetModule(getters) {
  return (module) => getters['liffModule/getModule'](module)
}

function useGetModuleConfig(getters) {
  return (module) => useGetModule(getters)(module).module_config || {}
}

function useGetModulePermissions(getters) {
  return (module) => useGetModuleConfig(getters)(module).permissions?.filter(x => x != 'none') || []
}

function getModulesFromRoute({ meta }) {
  if (Array.isArray(meta.modules)) {
    return meta.modules
  }

  return []
}

function getGeneralPermission() {
  return store.state.liffGeneral.moduleConfig?.permissions?.filter(x => x != 'none') || []
}

export default function auth(context) {
  const { to, next } = context
  const getModulePermissions = useGetModulePermissions(store.getters)
  const modules = _.uniq(to.matched.flatMap(getModulesFromRoute))
  const permissions = _.uniq(modules.flatMap(getModulePermissions)).length > 0 ?
    _.uniq(modules.flatMap(getModulePermissions)) : getGeneralPermission()
  //const permissions = _.uniq(modules.flatMap(getModulePermissions))
  const customer = store.state.liffAuth.user

  console.log(permissions)

  CheckerPipeline
    .make(context, customer, permissions)
    .through([RegisteredOnly, PhoneVerifiedOnly])
    .then(next)
}
