export default {
  namespaced: true,
  state: {
    type: null,
    query: {
      phone: null,
      source: null,
      coupon_code: null,
      qrcode: null,
      store_code: null,
      brand: null,
      event_name: null,
    },
  },
  getters: {
    queryData({query}) {
      return query;
    },
    couponType({type}) {
      return type;
    }
  },
  mutations: {
    SET_QUERY(state, query) {
      state.query = query;
    },
    SET_COUPON_TYPE(state, type) {
      state.type = type;
    },
    RESET_DATA(state) {
      state.query = {
        phone: null,
        source: null,
        coupon_code: null,
        qrcode: null,
        store_code: null,
        brand: null,
      }
      state.type = null
    }
  },
  actions: {
    updateQuery(context, params) {
      let query = context.state.query
      query = Object.assign(query, params)
      context.commit('SET_QUERY', query)
    },
    setQuery(context, query) {
      context.commit('SET_QUERY', {
          phone: query.phone,
          source: query.source,
          coupon_code: query.coupon_code,
          qrcode: query.qrcode,
          store_code: query.store_code,
          brand: query.brand,
          event_name: query.event_name,
      })
      context.commit('SET_COUPON_TYPE', query.type)
    },
    resetQuery(context) {
      context.commit('RESET_DATA')
    },
  },
};
