import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'

/**
 * middleware: initGeneralData
 *
 * 事先取得一些必要的資料到 Vuex 中
 */
export default async function initDashboardGeneralData({ next }) {
  await Promise.all([
    new Promise((resolve) => {
      if (! store.state.general.myBranches || ! store.state.general.currentBranch) {
        store.dispatch('general/fetchBranches').then(() => {
          resolve()
        })
      } else {
        resolve()
      }
    }),
    new Promise((resolve) => {
      if (! store.state.general.myOrganizations) {
        store.dispatch('general/fetchMyOrganizations')
          .catch((error) => {
            console.debug(error)
          })
          .finally(() => {
            resolve();
          })
      } else {
        resolve()
      }
    }),
    new Promise((resolve) => {
      store.dispatch('general/fetchDashboardModule').catch(error => console.debug(error)).finally(resolve())
    }),
  ]);

  // 確認目前存取的 Organization 是否在 myOrganizations
  if (store.state.general.organization && store.state.general.myOrganizations) {
    const existed = store.state.general.myOrganizations.find(organization => {
      return store.state.general.organization.id === organization.id
    })

    if (existed === undefined) {
      await Swal.fire({
        title: '錯誤',
        text: '您並沒有此組織的權限，請問是否登出改用其他帳號登入？',
        type: 'error',
        confirmButtonText: '登出',
        showCancelButton: true,
        cancelButtonText: '返回',
      }).then((result) => {
        if (result.value) {
          store.dispatch('auth/logout').then(() => {
            const org_code = store.state.general.currentOrgCode ?? null
            router.push({ name: 'AuthLogin', params: { org_code: org_code }})
          });
        } else {
          let organization = store.state.general.myOrganizations.find((organization) => organization.id === store.state.auth.user.current_org_id)

          if (organization === undefined) {
            organization = store.state.general.myOrganizations[0]
          }
          return router.push({
            name: 'DashboardHome',
            params: {
              org_code: organization.code
            }
          })
        }
      })
    }
  }

  if (!store.state.general.myOrganizations) {
    store.dispatch('auth/logout').then(() => {
      const org_code = store.state.general.currentOrgCode ?? null
      router.push({ name: 'AuthLogin', params: { org_code: org_code }})
    });
  }

  return next()
}
