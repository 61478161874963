import auth from '@/apis/liff/v2/auth'

export default {
  namespaced: true,
  state: {
    user: {},
    token: null,
    isLogin: false,
    phoneVerifying: null,
    newVerifying: null,
  },
  getters: {
    user({user}) {
      return user;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
      state.isLogin = (token !== null)
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_MOBILE(state, mobile) {
      state.user.mobile = mobile
    },
    SET_PHONE_VERIFYING(state, phone) {
      state.phoneVerifying = phone;
    },
    SET_NEW_VERIFYING(state, newVerifying) {
      state.newVerifying = newVerifying;
    },
  },
  actions: {
    async fetchMe (context) {
      let response = await auth.fetchMe();
      if (response.data.status === 'success') {
        context.commit("SET_USER", response.data.data);
      }
    },
  },
};
